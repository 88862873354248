import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';

const OrderBulk = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const [collections] = useState([
    'DEI ELECTI',
    'Ye’s salvation',
    'sportsman',
    'omo naija',
    'cold blooded',
    'noir',
  ]); // Updated collections list
  const [activeCollection, setActiveCollection] = useState('DEI ELECTI'); // Default collection

  // Fetch products when the active collection changes
  useEffect(() => {
    fetchProductsByCollection(activeCollection);
  }, [activeCollection]);

  const fetchProductsByCollection = async (collection) => {
    setLoading(true);
    try {
      const response = await axios.get(
        // `http://localhost:3005/admin/products/${collection}`
        `https://humandiagrams-backend.onrender.com/admin/products/${collection}`
      );
      setProducts(response.data.products);
    } catch (error) {
      console.log('Error fetching products for collection:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setUpdatedPrice(product.price);
    setUpdatedQuantity(product.number);
    setModalIsOpen(true);
  };

  const handleUpdateProduct = async () => {
    try {
      const updatedProduct = {
        price: updatedPrice,
        number: updatedQuantity,
      };
      const response = await axios.put(
        `http://localhost:3005/admin/update/${selectedProduct._id}`,
        updatedProduct
      );
      if (response.data.success) {
        alert('Product updated successfully!');
        setModalIsOpen(false);
        fetchProductsByCollection(activeCollection); // Refresh products for the active collection
      }
    } catch (error) {
      console.log('Error updating product:', error);
      alert('Failed to update product');
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const response = await axios.delete(
        `http://localhost:3005/admin/delete/${productId}`
      );
      if (response.data.success) {
        alert('Product deleted successfully!');
        fetchProductsByCollection(activeCollection);
      }
    } catch (error) {
      console.log('Error deleting product:', error);
      alert('Failed to delete product');
    }
  };

  return (
    <div className="p-4 max-sm:ml-0 ml-60 mt-20">
      <h1 className="text-2xl font-bold mb-4">View Products</h1>

      {/* Render collection buttons */}
      <div className="flex mb-4 flex-wrap gap-2">
        {collections.map((collection) => (
          <button
            key={collection}
            onClick={() => setActiveCollection(collection)}
            className={`px-4 py-2 rounded ${
              activeCollection === collection ? 'bg-blue-500 text-white' : 'bg-white text-black'
            }`}
          >
            {collection}
          </button>
        ))}
      </div>

      {/* Loader or Products */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <BounceLoader color="#4A90E2" loading={loading} size={100} />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {products.map((product) => (
            <div key={product._id} className="border p-4 rounded shadow-md">
              <div>
                <img src={product.image} alt={product.title} className="w-full h-40 object-cover" />
              </div>
              <h2 className="text-lg font-bold">{product.title}</h2>
              <p>Price: ${product.price}</p>
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded mt-2"
                onClick={() => handleEditProduct(product)}
              >
                Edit
              </button>
              <button
                className="bg-red-500 text-white px-2 py-1 rounded mt-2 ml-2"
                onClick={() => handleDeleteProduct(product._id)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Edit Modal */}
      {modalIsOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg">
            <h2 className="text-lg font-bold">Edit Product</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Price</label>
                <input
                  type="number"
                  value={updatedPrice}
                  onChange={(e) => setUpdatedPrice(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Quantity</label>
                <input
                  type="number"
                  value={updatedQuantity}
                  onChange={(e) => setUpdatedQuantity(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
              <button
                type="button"
                onClick={handleUpdateProduct}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Update
              </button>
              <button
                type="button"
                onClick={() => setModalIsOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderBulk;

import React from "react";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FiX } from "react-icons/fi";
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import usaflg from "../public/Ellipse 1.svg"
import arrowup from "../public/Vector (Stroke).svg"

const Footer = () => {
  return (
    <footer className="bg-white py-12 border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:justify-between">
          {/* Left: Newsletter Section */}
          <div className="md:w-1/2 bg-[#0A0A0A0D] max-sm:bg-white text-left max-sm:text-center max-sm:p-0 p-7">
            <h2 className="text-xl font-semibold">STAY AHEAD OF THE TRENDS</h2>
            <p className="text-gray-500 mt-2">
              Subscribe to our newsletter and be the first to discover elusive drops, style tips, and limited-time offers railed for the trendsetters.
            </p>
          </div>

          {/* Right: Subscribe Section */}
          <div className="md:w-1/2 mt-8 md:mt-0 flex flex-col items-start md:items-start p-5 max-sm:p-0">
            <h3 className="text-lg font-semibold">SUBSCRIBE</h3>
            <form className="mt-2 flex w-full max-w-md">
              <input
                type="email"
                placeholder="Enter email address"
                className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-800"
              />
              <button
                type="submit"
                className="flex items-center px-4 py-2 text-black"
              >
                Subscribe   <img src={arrowup} className="ml-2" />
              </button>
            </form>
            <p className="text-gray-500 text-sm mt-2">
              By subscribing you are agreeing to our privacy policy
            </p>
          </div>
        </div>

        <div className="mt-12 max-sm:mt-5 w-full border-t max-sm:border-none border-gray-300 pt-8">
          <div className="md:flex md:justify-between w-full">
            {/* Currency and Country Selectors */}
            <div className=" flex items-start justify-start w-60 text-left">
            <div className="flex-col justify-start items-start">
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Currency</label>
                <Menu as="div" className="relative inline-block text-left mt-2">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                      <img src={usaflg} className="mr-2" />
                      <p className=" text-center flex items-center mt-3"> ($) United States Dollar</p>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Menu.Items className="absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-gray-100" : ""
                              } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                            >
                              (S) United States Dollar
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-gray-100" : ""
                              } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                            >
                              € Euro
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

              <div>
                <label className="block text-gray-700 font-semibold">Country</label>
                <Menu as="div" className="relative inline-block text-left mt-2">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                    <img src={usaflg} className="mr-2" />
                    <p className=" text-center flex items-center mt-3"> USA </p>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Menu.Items className="absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-gray-100" : ""
                              } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                            >
                              USA
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-gray-100" : ""
                              } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                            >
                              Canada
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            </div>

            {/* Links Section */}
            <div className="md:w-96 mt-8 md:mt-0 w-2/2 ">
              <h3 className="font-semibold text-left max-sm:text-left ">Links</h3>
              <ul className="mt-4 grid text-left grid-cols-4 font-medium max-sm:grid-cols-1 gap-2  max-sm:text-xs text-gray-700 underline">
                <li><a href="#" className="hover:underline ">Shipping</a></li>
                <li><a href="#" className="hover:underline">Aura</a></li>
                <li><a href="#" className="hover:underline">Rizz</a></li>
                <li><a href="#" className="hover:underline whitespace-nowrap">Limited Collection</a></li>
                <li><a href="#" className="hover:underline mt-3">Dei Electi</a></li>
              </ul>
            </div>

            {/* Social Icons Section */}
            <div className=" mt-8 md:mt-0 flex justify-end max-sm:justify-start">
              <div>
                <h3 className="font-semibold max-sm:text-left">Socials</h3>
                <div className="mt-4 flex space-x-4 text-2xl">
                  <a href="#" className="hover:text-gray-600">
                    <FaInstagram />
                  </a>
                  <a href="#" className="hover:text-gray-600">
                    <FaTiktok />
                  </a>
                  <a href="#" className="hover:text-gray-600">
                    <FiX />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Bottom Text */}
          <div className="mt-8 text-center border-t border-gray-300 mx-60 max-sm:mx-2 text-gray-500 text-sm">
            Dei Electi © 2024
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useState } from 'react';
import Navbar from '../components/navbar';
import Footer from "../components/footer"

import { useLocation } from "react-router-dom"; // Import useLocation

import productimage from "../public/collection1_1.svg"



const relatedProducts = [
  { name: 'Aura Hoodie', price: '$170', image: productimage },
  { name: 'Aura Pants', price: '$170', image: productimage },
  { name: 'Aura Hoodie', price: '$170', image: productimage },
  { name: 'Aura Hoodie', price: '$170', image: productimage },
  { name: 'Aura Hoodie', price: '$170', image: productimage },
  { name: 'Aura Hoodie', price: '$170', image: productimage },
];

const Signleproduct = () => {
  const location = useLocation(); // Get the passed product data
  const productimagee = location.state || {}; // Ensure there's a fallback in case state is missing

  // Example Product and Related Products Data
  const product = {
    name: productimagee.title,
    price: productimagee.price,
    colors: ['#000000', '#5F67D5', '#E33288', '#DEB887', '#F4F7F2'],
    sizes: ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
    shippingInfo: 'Shipping to United Kingdom',
    images: [
      productimagee.image,
      productimagee.image,
      productimagee.image,
    ],
  };

  // Initialize state after product is defined
  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedSize, setSelectedSize] = useState('M');

  return (
    <>
      <Navbar />

      <div className="max-w-7xl mx-auto p-6">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Section: Product Images */}
          <div className="w-[30%] max-sm:w-[100%] lg:w-1/2 flex">
            <div className="flex-col gap-2 mr-5 max-sm:hidden">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={selectedImage}
                  alt={`Thumbnail ${index}`}
                  className={` w-96 mb-3 h-20 object-cover cursor-pointer border ${selectedImage === image ? 'border-black' : 'border-gray-200'}`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
            <img src={selectedImage} alt="Product" className="w-[100%] h-auto max-sm:w-[100%]" />
          </div>

          {/* Right Section: Product Details */}
          <div className="w-full text-left">
            <h1 className="text-2xl font-bol uppercase">{product.name}</h1>
            <p className="text-lg text-gray-500 mt-2">€{product.price} + €5 Shipping</p>
            <p className="text-sm text-gray-400 mt-1">{product.shippingInfo}</p>

            {/* Color Options */}
            <div className="mt-4">
              <p className="text-sm font-semibold">Colour: {selectedColor}</p>
              <div className="flex gap-2 mt-2">
                {product.colors.map((color, index) => (
                  <button
                    key={index}
                    className={`w-8 h-8 rounded-full border-2 ${selectedColor === color ? 'border-black' : 'border-gray-300'}`}
                    style={{ backgroundColor: color }}
                    onClick={() => setSelectedColor(color)}
                  ></button>
                ))}
              </div>
            </div>

            {/* Size Options */}
            <div className="mt-4">
              <p className="text-sm font-semibold">Size</p>
              <div className="flex gap-2 mt-2">
                {product.sizes.map((size, index) => (
                  <button
                    key={index}
                    className={`px-4 py-2 border rounded ${selectedSize === size ? 'border-black' : 'border-gray-300'}`}
                    onClick={() => setSelectedSize(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            {/* Add to Cart Button */}
            <button className="mt-6 bg-black text-white py-3 px-6 w-full text-center font-semibold">
              Add to Cart
            </button>
          </div>
        </div>

        {/* More Like This Section */}
        <div className="mt-12">
          <h2 className="text-xl font-semibold mb-6">More Like This</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {relatedProducts.map((product, index) => (
              <div key={index} className="text-center">
                <img src={product.image} alt={product.name} className="w-full h-64 object-cover mb-2" />
                <p className="font-semibold">{product.name}</p>
                <p className="text-gray-500">{product.price}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signleproduct;

import React from 'react';
import Navbar from '../components/navbar';
import Footer from "../components/footer"

// You can replace this with the actual image path
import backgroundImage from '../public/about-img.png'; 
import designerImage from '../public/designer_image.jpeg';

import icon1 from "../public/exclusivedesign_aboutoage icon.svg"
import icon2 from "../public/search_aboutpage icon.svg"
import icon3 from "../public/bold_aboutage icon.svg"

const AboutSection = () => {
  return (
    <>
    <Navbar />
    <div>
      {/* Banner Section */}
      <div 
        className="relative bg-cover bg-center h-[500px]" 
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-center flex flex-col justify-center items-center h-full text-white px-6">
          <h1 className="text-xl lg:text-6xl font-normal mb-6">
            WHERE ELEGANCE MEETS INDIVIDUALITY.
          </h1>
          <p className="max-w-5xl mx-2 text-lg lg:text-xl">
            At Dei Electi, we believe fashion is an expression of individuality, where elegance meets exclusivity. Our brand is dedicated to those who seek to elevate their style with carefully crafted pieces that reflect both sophistication and boldness. Every design is a statement of quality, where the finest details are meticulously considered to embody true luxury.
          </p>
        </div>
      </div>

      {/* What Makes Us Stand Out Section */}
      <div className="text-center my-12">
        <h2 className="text-2xl lg:text-4xl font-bold mb-8">
          WHAT MAKES US STAND OUT
        </h2>

        <div className="flex flex-col lg:flex-row justify-center gap-8">
          {/* Card 1 */}
          <div className="border p-6 max-w-sm mx-auto lg:mx-0">
            <div className="mb-4 text-3xl flex items-center justify-center">
                <img src={icon1} />
            </div>
            <h3 className="text-xl font-bold mb-4">EXCLUSIVITY IN DESIGN</h3>
            <p>
              Each piece is crafted with a focus on individuality, offering fashion that is unique and tailored for the exceptional.
            </p>
          </div>

          {/* Card 2 */}
          <div className="border p-6 max-w-sm mx-auto lg:mx-0">
          <div className="mb-4 text-3xl flex items-center justify-center">
            <img src={icon2} />
            </div>
            <h3 className="text-xl font-bold mb-4">ATTENTION TO DETAIL</h3>
            <p>
              From fabric selection to intricate finishes, every design reflects a commitment to high-quality craftsmanship and sophistication.
            </p>
          </div>

          {/* Card 3 */}
          <div className="border p-6 max-w-sm mx-auto lg:mx-0">
          <div className="mb-4 text-3xl flex items-center justify-center">
            <img src={icon3} />
            </div>
            <h3 className="text-xl font-bold mb-4">BOLD ELEGANCE</h3>
            <p>
              Our brand merges timeless elegance with bold, modern aesthetics, creating a perfect balance for those who want to make a statement.
            </p>
          </div>
        </div>
      </div>

      <div
      className="relative bg-cover bg-center h-[500px] flex items-center justify-center text-center"
      style={{ backgroundImage: `url(${designerImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-40"></div> {/* Dark Overlay */}

      <div className="relative z-10 text-white px-4">
        <h1 className="text-4xl lg:text-5xl font-bold mb-6">
          MEET THE DESIGNER
        </h1>
        <p className="max-w-6xl mx-auto text-lg lg:text-xl">
          At Dei Electi, we believe fashion is an expression of individuality, where elegance meets exclusivity. 
          Our brand is dedicated to those who seek to elevate their style with carefully crafted pieces that reflect 
          both sophistication and boldness. Every design is a statement of quality, where the finest details are 
          meticulously considered to embody true luxury.
        </p>
      </div>
    </div>
    </div>
    <Footer />
    </>
  );
};

export default AboutSection;

import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa"; // FontAwesome for the filter icon
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { ClipLoader } from "react-spinners"; // Import react-spinners
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const Back2School = () => {
  const [products, setProducts] = useState([]); // State to hold products
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate(); // Initialize the navigate hook

  // Fetch products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          // "http://localhost:3005/admin/main/products"
          "https://humandiagrams-backend.onrender.com/admin/main/products"
        ); // Replace with your backend URL
        if (!response.ok) throw new Error("Failed to fetch products");
        const data = await response.json();
        setProducts(data); // Update state with fetched products
      } catch (error) {
        console.error("Error fetching products:", error.message);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (product) => {
    // Pass product data to the SingleProduct page
    navigate("/product", { state: product });
  };

  return (
    <>
      <Navbar />
      <div className="p-6 max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Back 2 School</h1>
          <button className="flex items-center space-x-2 text-sm font-medium">
            <span>Filter by</span>
            <FaFilter className="text-xl" />
          </button>
        </div>

        {/* Loading Spinner */}
        {loading ? (
          <div className="flex justify-center items-center h-96">
            <ClipLoader size={50} color={"#4A90E2"} loading={loading} />
          </div>
        ) : (
          // Product Grid
          <div className="grid grid-cols-2 gap-6 max-sm:gap-1  md:grid-cols-4">
            {products.map((product) => (
              <div
                key={product._id} // Use unique MongoDB `_id` as key
                className="text-center cursor-pointer p-4 max-sm:p-1 mb-14 hover:shadow-lg"
                onClick={() => handleProductClick(product)}
                style={{ minHeight: "300px" }} // Set consistent height for all cards
              >
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-72 object-cover mb-4" // Consistent image height
                />
                <h2 className="text-lg font-semibold truncate uppercase">{product.title}</h2>
                <p className="text-gray-600">${product.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Back2School;

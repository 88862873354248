import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faShoppingCart, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom'; 
import logo from "../public/dei electi.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false); // State to control cart sidebar
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to control dropdown menu

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen); // Toggles cart visibility
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggles dropdown visibility
  };

  useEffect(() => {
    if (isOpen || isCartOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when menu/cart is open
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, isCartOpen]);

  const activeLinkClass = "text-black underline mr-5 ml-5";
  const inactiveLinkClass = "text-gray-500 hover:text-gray-600 mr-5 ml-5";

  return (
    <nav className="bg-white border-b-2 border-gray-200 w-screen">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        
        {/* Hamburger Menu for Mobile */}
        <button
          className="block text-xl lg:hidden"
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="text-black" />
        </button>

        {/* Logo centered */}
        <div className="text-2xl font-bold tracking-widest mx-auto lg:mx-0 max-sm:ml-24">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="h-auto w-auto" />
          </NavLink>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex">
          <NavLink 
            exact 
            to="/" 
            className={({ isActive }) => isActive ? activeLinkClass : inactiveLinkClass}
          >
            HOME
          </NavLink>

          <NavLink 
            to="/about-us" 
            className={({ isActive }) => isActive ? activeLinkClass : inactiveLinkClass}
          >
            ABOUT US
          </NavLink>

          {/* Collection Link with Dropdown */}
          <div className="relative">
            <button 
              onClick={toggleDropdown} 
              className={`flex items-center ${isDropdownOpen ? activeLinkClass : inactiveLinkClass}`}
            >
              COLLECTION
              <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
            </button>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute mt-2 bg-white shadow-lg py-2 rounded-md">
                <NavLink 
                  to="/dei-electi" 
                  className="text-gray-500 hover:text-gray-700 block px-4 py-2"
                  onClick={toggleMenu}
                >
                  DEI ELECTI
                </NavLink>
                <NavLink 
                  to="/yes-salvation" 
                  className="text-gray-500 hover:text-gray-700 block px-4 py-2"
                  onClick={toggleMenu}
                >
                  Ye’s Salvation
                </NavLink>
                <NavLink 
                  to="/sportsman" 
                  className="text-gray-500 hover:text-gray-700 block px-4 py-2"
                  onClick={toggleMenu}
                >
                  Sportsman
                </NavLink>
                <NavLink 
                  to="/omo-naija" 
                  className="text-gray-500 hover:text-gray-700 block px-4 py-2"
                  onClick={toggleMenu}
                >
                  Omo Naija
                </NavLink>
                <NavLink 
                  to="/cold-blooded" 
                  className="text-gray-500 hover:text-gray-700 block px-4 py-2"
                  onClick={toggleMenu}
                >
                  Cold Blooded
                </NavLink>
                <NavLink 
                  to="/noir" 
                  className="text-gray-500 hover:text-gray-700 block px-4 py-2"
                  onClick={toggleMenu}
                >
                  Noir
                </NavLink>
              </div>
            )}
          </div>
        </div>

        {/* Right Side Icons */}
        <div className="lg:flex max-sm:flex space-x-6 items-center mr-3">
          {/* Cart Icon */}
          <button  className="hover:text-gray-600 max-sm:hidden">
            SIGN UP
          </button>
          <button onClick={toggleCart} className="hover:text-gray-600">
            <FontAwesomeIcon icon={faShoppingCart} className="text-lg" />
          </button>
          <button className="hover:text-gray-600">
            <FontAwesomeIcon icon={faSearch} className="text-lg" />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`lg:hidden ${isOpen ? 'block' : 'hidden'} bg-white w-full z-50 absolute h-screen border-t-2 border-gray-200 transition-all`}
      >
        <div className="flex flex-col items-start space-y-4 py-4 pl-4">
          <NavLink 
            exact 
            to="/" 
            className={({ isActive }) => isActive ? activeLinkClass : inactiveLinkClass}
            onClick={toggleMenu}
          >
            HOME
          </NavLink>

          <NavLink 
            to="/about-us" 
            className={({ isActive }) => isActive ? activeLinkClass : inactiveLinkClass}
            onClick={toggleMenu}
          >
            ABOUT US
          </NavLink>

          {/* Collection Link with Dropdown */}
          <div className="relative">
            <button 
              onClick={toggleDropdown} 
              className={`flex items-center ${isDropdownOpen ? activeLinkClass : inactiveLinkClass}`}
            >
              COLLECTION
              <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
            </button>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="pl-4 mt-2 space-y-2">
                <NavLink 
                  to="/dei-electi" 
                  className="text-gray-500 hover:text-gray-700 block"
                  onClick={toggleMenu}
                >
                  DEI ELECTI
                </NavLink>
                <NavLink 
                  to="/yes-salvation" 
                  className="text-gray-500 hover:text-gray-700 block"
                  onClick={toggleMenu}
                >
                  Ye’s Salvation
                </NavLink>
                <NavLink 
                  to="/sportsman" 
                  className="text-gray-500 hover:text-gray-700 block"
                  onClick={toggleMenu}
                >
                  Sportsman
                </NavLink>
                <NavLink 
                  to="/omo-naija" 
                  className="text-gray-500 hover:text-gray-700 block"
                  onClick={toggleMenu}
                >
                  Omo Naija
                </NavLink>
                <NavLink 
                  to="/cold-blooded" 
                  className="text-gray-500 hover:text-gray-700 block"
                  onClick={toggleMenu}
                >
                  Cold Blooded
                </NavLink>
                <NavLink 
                  to="/noir" 
                  className="text-gray-500 hover:text-gray-700 block"
                  onClick={toggleMenu}
                >
                  Noir
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Cart Sidebar */}
      <div
        className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg z-50 transform transition-transform ${
          isCartOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Close Button */}
        <div className="p-4 flex justify-between items-center border-b">
          <h2 className="text-lg font-semibold">Your Cart</h2>
          <button onClick={toggleCart}>
            <FontAwesomeIcon icon={faTimes} className="text-gray-600" />
          </button>
        </div>

        {/* Cart Content */}
        <div className="p-4">
          <p>Your cart is currently empty.</p>
          {/* You can map through cart items here */}
        </div>
      </div>

      {/* Backdrop (optional for better UX) */}
      {isCartOpen && (
        <div
          onClick={toggleCart}
          className="fixed inset-0 bg-black opacity-50 z-40"
        ></div>
      )}
    </nav>
  );
};

export default Navbar;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authimage from "../../public/item.jpg";
import { Link } from 'react-router-dom';

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      firstName: document.getElementById('firstName').value,
      lastName: document.getElementById('lastName').value,
      email: document.getElementById('email').value,
      password: document.getElementById('password').value
    };

    try {
      const response = await fetch(
        'https://humandiagrams-backend.onrender.com/admin/signup'
        // "http://localhost:3005/admin/signup"
        ,
         {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (response.ok) {
        alert('Sign up successful!');
        navigate('/admin/viewclothe');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during signup');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 max-sm:bg-white max-sm:w-full max-sm:p-5 w-screen">
      {/* Container */}
      <div className="flex bg-white w-full shadow-lg max-sm:shadow-none rounded-lg overflow-hidden max-sm:w-full">
        
        {/* Sign Up Form */}
        <div className="w-1/2 p-8 max-sm:p-0 max-sm:w-full">
          <h2 className="text-2xl font-bold mb-6 text-gray-700">Sign up</h2>
          <form onSubmit={handleSignup} className="text-left">
            
            {/* First Name */}
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="firstName">
                First name
              </label>
              <input
                id="firstName"
                type="text"
                placeholder="Enter first name"
                className="border w-full p-3 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              />
            </div>

            {/* Last Name */}
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="lastName">
                Last name
              </label>
              <input
                id="lastName"
                type="text"
                placeholder="Enter last name"
                className="border w-full p-3 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              />
            </div>

            {/* Email */}
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter email"
                className="border w-full p-3 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              />
            </div>

            {/* Password */}
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                placeholder="Enter password"
                className="border w-full p-3 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              />
            </div>

            {/* Sign Up Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
              disabled={loading}
            >
              {loading ? (
                <svg className="animate-spin h-5 w-5 mx-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
              ) : (
                "Sign up"
              )}
            </button>

            {/* Sign In Link */}
            <div className="text-center mt-4 text-gray-600">
              Already have an account? <Link to="/admin/auth/login" className="text-blue-500 hover:underline">Sign in</Link>
            </div>
          </form>
        </div>

        {/* Image Section */}
        <div className="w-1/2 max-sm:hidden">
          <img
            src={authimage}
            alt="Shopping Cart"
            className="object-cover h-full w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa"; // FontAwesome for the filter icon
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { ClipLoader } from "react-spinners"; // Import react-spinners
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

import axios from 'axios';

const Coldblooded = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate(); // Initialize the navigate hook

  const collection = 'cold blooded' // Default collection
  const [products, setProducts] = useState([]); // State to hold products5555

  // Fetch products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
        //  `http://localhost:3005/admin/products/${collection}`
        `https://humandiagrams-backend.onrender.com/admin/products/${collection}`
        ); // Replace with your backend URL
        setProducts(response.data.product); // Update state with fetched products
        console.log(response.data.product)
      } catch (error) {
        console.error("Error fetching products:", error.message);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (product) => {
    // Pass product data to the SingleProduct page
    navigate("/product", { state: product });
  };

  return (
    <>
      <Navbar />
      <div className="p-6 max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Cold Blood</h1>
          <button className="flex items-center space-x-2 text-sm font-medium">
            <span>Filter by</span>
            <FaFilter className="text-xl" />
          </button>
        </div>

        {/* Loading Spinner */}
        {loading ? (
          <div className="flex justify-center items-center h-96">
            <ClipLoader size={50} color={"#4A90E2"} loading={loading} />
          </div>
        ) : (
          // Product Grid
          <div className="grid grid-cols-2 gap-6 max-sm:gap-1 md:grid-cols-4">
            {products.map((product) => (
              <div
                key={product._id} // Use unique MongoDB `_id` as key
                className="text-center cursor-pointer p-4 max-sm:p-1 mb-10 hover:shadow-lg"
                onClick={() => handleProductClick(product)}
                style={{ minHeight: "300px" }} // Set consistent height for all cards
              >
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-72 object-cover mb-4" // Consistent image height
                />
                <h2 className="text-lg font-semibold truncate uppercase">{product.title}</h2>
                <p className="text-gray-600">${product.price}</p>

              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Coldblooded;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ProductSection = ({collectionName}) => {
  const [products, setProducts] = useState([]);
  const [randomProducts, setRandomProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          // `http://localhost:3005/admin/main/products`
           `https://humandiagrams-backend.onrender.com/admin/main/products`
        );
        const allProducts = response.data;
        
        // Remove toLowerCase() to maintain original case
        const collectionProducts = allProducts
          .filter(product => product.collection === collectionName)
          .slice(0, 4);

        setProducts(collectionProducts);
        console.log(collectionProducts);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };

    if (collectionName) {
      fetchProducts();
    }
  }, [collectionName]);

  return (
    <div className="grid grid-cols-4 max-sm:grid-cols-2 w-full">
      {products.map((product, index) => (
        <div key={index} className="">
          <img src={product.image} alt={product.name} className="p-3 w-[500px] h-[390px] max-sm:h-[230px]" />
        </div>
      ))}
    </div>
  );
};

export default ProductSection;

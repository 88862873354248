import React, { useState } from "react";
import axios from "axios";
import image from "../public/waitlistimage.jpeg";
import user from "../public/user.svg";
import envelope from "../public/envelope.svg";
import arrowright from "../public/arrow-right.svg";

const WaitlistSection = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isFormValid = formData.fullName.trim() !== "" && formData.email.trim() !== "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!isFormValid) return;

    setIsSubmitting(true);

    try {
      // Sending data to the backend
      await axios.post(
        // "http://localhost:3005/admin/send-email"
        "https://humandiagrams-backend.onrender.com/admin/send-email"
        ,

         formData);
      setSuccessMessage("Your details have been submitted successfully!");
      setFormData({ fullName: "", email: "" }); // Reset form
    } catch (err) {
      setError("Something went wrong. Please try again.");
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="w-screen">
        <div
          className="relative h-screen bg-cover bg-bottom bg-no-repeat flex items-center justify-center text-white w-[100%"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="absolute inset-0 bg-black opacity-75"></div>
          {/* Overlay for darkening background */}

          <div className="relative z-10 text-center px-4">
            <h1 className="text-4xl font-bold mb-4">BE AMONG THE CHOSEN</h1>
            <p className="text-lg mb-8">
              Step into the Dei Electi experience - Reserved for the exceptional
            </p>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex items-center bg-gray-800 bg-opacity-75 px-4 py-3">
                <img src={user} alt="User Icon" className="w-6 h-6 mr-3" />
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  placeholder="Full Name"
                  className="bg-transparent w-full outline-none text-white placeholder-gray-400"
                />
              </div>

              <div className="flex items-center bg-gray-800 bg-opacity-75 px-4 py-3">
                <img src={envelope} alt="Envelope Icon" className="w-6 h-6 mr-3" />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email Address"
                  className="bg-transparent w-full outline-none text-white placeholder-gray-400"
                />
              </div>

              <button
                type="submit"
                disabled={!isFormValid || isSubmitting}
                className={`flex items-center justify-center w-full px-4 py-3 font-semibold transition duration-300 ${
                  isFormValid
                    ? "bg-gray-800 text-gray-400 hover:bg-opacity-90"
                    : "bg-gray-600 cursor-not-allowed text-gray-500"
                }`}
              >
                {isSubmitting ? (
                  <div className="flex items-center space-x-2">
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    <span>Submitting...</span>
                  </div>
                ) : (
                  <>
                    Join The Waitlist
                    <img src={arrowright} alt="Arrow Right Icon" className="ml-2 w-5 h-5" />
                  </>
                )}
              </button>
            </form>

            {error && <p className="text-red-500 mt-4">{error}</p>}
            {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitlistSection;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon
import authimage from "../../public/item.jpg";
import { Link } from 'react-router-dom';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when the request starts
    setError(null);   // Reset any previous errors

    try {
      const response = await axios.post('https://humandiagrams-backend.onrender.com/admin/login', formData);
      const { token, role, user } = response.data;

      const data = await response.json();
      if (response.ok) {
        alert('Sign up successful!');
        navigate('/admin/dashboard');
      }
    } catch (err) {
      setError('Invalid email or password');
    } finally {
      setLoading(false); // Set loading state to false when the request completes
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 max-sm:bg-white max-sm:w-full w-screen">
      <div className="flex bg-white shadow-lg max-sm:shadow-none max-sm:w-full rounded-lg overflow-hidden w-full">
        <div className="w-1/2 p-8 max-sm:w-full ">
          <h2 className="text-2xl font-bold mb-6 text-gray-700">Login</h2>
          <form onSubmit={handleSubmit} className=' text-left'>
            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
                className="border w-full p-3 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-600 text-sm font-semibold mb-2" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter password"
                className="border w-full p-3 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              />
            </div>

            <div className="text-right mb-4">
              <a href="#" className="text-blue-500 text-sm hover:underline">Forgot Password?</a>
            </div>

            {/* Button with spinner */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300 flex justify-center items-center"
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" /> {/* Spinner icon */}
                  Logging in...
                </>
              ) : (
                'Login'
              )}
            </button>

             {/* Sign In Link */}
             <div className="text-center mt-4 text-gray-600">
              Dont have an account? <Link to="/admin/auth/signup" className="text-blue-500 hover:underline">Log in</Link>
            </div>

            {error && <p className="text-red-500 mt-4">{error}</p>}
          </form>
        </div>

        <div className="w-1/2 max-sm:hidden">
          <img src={authimage} alt="Auth" className="object-cover h-full w-full" />
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faBoxOpen,
  faUsers,
  faClipboardList,
  faSignOutAlt,
  faChartBar,
  faCommentDots,
  faTags,
  faCubes,
  faBars,
  faTimes
} from '@fortawesome/free-solid-svg-icons';


const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar visibility
  const location = useLocation(); // Get the current route



  // Function to check if a link is active
  const isActiveLink = (path) => location.pathname === path ? "bg-[#E7F2FC] text-[#0655A2]" : "text-[#919191]";

  // Toggle sidebar visibility
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div>
      {/* Hamburger icon for mobile */}
      <div className="lg:hidden fixed top-4 left-4 z-50">
        <button onClick={toggleSidebar} aria-label="Toggle Sidebar" className="text-3xl">
          <FontAwesomeIcon icon={sidebarOpen ? faTimes : faBars} />
        </button>
      </div>

      {/* Background overlay for mobile */}
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-40 ${sidebarOpen ? 'block' : 'hidden'} lg:hidden`}
        onClick={toggleSidebar}
      />
      
      {/* Sidebar container */}
      <nav className={`h-screen w-64 bg-white shadow-md fixed z-50 transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
        {/* Logo */}
        <div className="p-4 text-center">
          {/* <img src={Logo} className="w-[80px] h-[80px]" alt="Company Logo" /> */}
        </div>

        {/* Sidebar Links */}
        <ul>

            
              <li className={`flex items-center py-4 px-6 hover:bg-blue-500 hover:text-white cursor-pointer ${isActiveLink('/SalesAgentDashboard')}`}>
                <FontAwesomeIcon icon={faTachometerAlt} className="mr-4" />
                <Link to="/admin/viewclothe">View Clothes</Link>
              </li>
              <li className={`flex items-center py-4 px-6 hover:bg-blue-500 hover:text-white cursor-pointer ${isActiveLink('/SalesCustomerDetails')}`}>
                <FontAwesomeIcon icon={faUsers} className="mr-4" />
                <Link to="/admin/dashboard ">Add Clothe</Link>
              </li>
             
       
           
        
          {/* Logout */}
          <li className="flex items-center py-4 px-6 text-[#919191] hover:bg-red-500 hover:text-white cursor-pointer">
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-4" />
            Logout
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
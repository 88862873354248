
import React, { useState } from 'react';
import axios from 'axios';
import { Cloudinary } from "@cloudinary/url-gen";
import { FaSpinner } from 'react-icons/fa'; // Import loading spinner
import Sidebar from "../sidebar";

// Initialize Cloudinary URL generator outside the component
const cld = new Cloudinary({ cloud: { cloudName: 'dwpoik1jm' } });

const categories = [
'i',
'ii',
'iii',
'iv',
'v',
'vi',
'vii',
'viii',
'ix',
'x',
];

const collections = [
'DEI ELECTI',
'Ye’s salvation',
'sportsman',
'omo naija',
'cold blooded',
'noir',
];

const AdminProductForm = () => {
const [title, setTitle] = useState('');
const [category, setCategory] = useState(categories[0]);
const [collection, setCollection] = useState(collections[0]); // New state for collection
const [price, setPrice] = useState('');

const [files, setFiles] = useState(null);
const [previewUrl, setPreviewUrl] = useState("");
const [loading, setLoading] = useState(false); // New loading state

const [error, setError] = useState('');
const [success, setSuccess] = useState("");

const handleChange = (e) => {
const selectedFiles = e.target.files;
const selectedFile = selectedFiles[0];

// Create FormData object
const formData = new FormData();
formData.append('file', selectedFile);
formData.append('upload_preset', 'giweexpv'); // Your Cloudinary upload preset

// Set the files state with the FormData object
setFiles(formData);

// Display the image
const imageUrl = URL.createObjectURL(selectedFile);
setPreviewUrl(imageUrl);
};

const updateUserInDatabase = async () => {
setLoading(true); // Set loading state to true
try {
  let imageUrl = ''; // Initialize image URL

  // Check if a new file is selected for upload
  if (files) {
    // Upload the new image to Cloudinary
    const response = await axios.post(
      'https://api.cloudinary.com/v1_1/dwpoik1jm/image/upload',
      files
    );
    imageUrl = response.data.secure_url; // Get the URL of the uploaded image from Cloudinary response
  }

  // Prepare the updated product data
  const updatedData = {
    title: title,
    category: category,
    collection: collection, // Add collection to product data
    price: price,
    image: imageUrl, // Use the image URL
  };

  console.log(updatedData);

  // Send the product data to the backend
  await axios.post(
    //  'http://localhost:3005/admin/add-product',
    "https://humandiagrams-backend.onrender.com/admin/add-product",
    updatedData
  );

  setSuccess('Product added successfully!');
  setTimeout(() => {
    setSuccess('');
  }, 5000);
} catch (error) {
  setError(error.message || 'Error uploading product');
  setTimeout(() => {
    setError('');
  }, 5000);
} finally {
  setLoading(false); // Stop loading after request completes
}
};

return (
<>
  <div className="bg-white mt-20 max-sm:ml-0 ml-60 p-6 max-sm:p-0 rounded-lg shadow-md max-sm:rounded-none max-sm:shadow-none w-full max-w-4xl mx-auto">
    {error && (
      <div
        id="pop-up"
        style={{ zIndex: 9999, borderTopWidth: "6px" }}
        className="fixed border-red-600 shadow-xl bg-white mt-24 xl:mt-28 mr-4 max-w-xs top-0 right-0 py-2 px-3"
      >
        <h2 className="font-bold tracking-wider">Profile Update Failed</h2>
        <p className="text-sm text-left tracking-wider pt-1">{error}</p>
      </div>
    )}

    {success && (
      <div
        id="pop-up"
        style={{ zIndex: 9999, borderTopWidth: "6px" }}
        className="fixed border-green-600 shadow-xl bg-white mt-24 xl:mt-28 mr-4 max-w-xs top-0 right-0 py-2 px-3"
      >
        <h2 className="font-bold tracking-wider">Data Updated</h2>
        <p className="text-sm text-left tracking-wider pt-1">{success}</p>
      </div>
    )}

    <h2 className="text-2xl font-semibold text-gray-800 mb-6">Add Clothe</h2>

    <div className="space-y-6">
      {/* Title Input */}
      <div>
        <label className="block text-gray-700 font-medium mb-2">Brand</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Enter product title"
        />
      </div>

      {/* Collection Section */}
      <div>
        <label className="block text-gray-700 font-medium mb-2">Collection</label>
        <select
          value={collection}
          onChange={(e) => setCollection(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
        >
          {collections.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>

      {/* Media Section */}
      <div>
        <label className="block text-gray-700 font-medium mb-2">Media</label>
        <div className="flex space-x-4">
          <input
            onChange={handleChange}
            type="file"
            className="bg-[#0655A2] text-white px-4 py-2 rounded shadow hover:bg-blue-700"
          />

          {previewUrl && (
            <img src={previewUrl} alt="Preview" className="w-26 h-20 rounded-md" />
          )}
        </div>
      </div>

      {/* Price Section */}
      <div>
        <label className="block text-gray-700 font-medium mb-2">Price</label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Enter price"
        />
      </div>
    </div>

    {/* Action Buttons */}
    <div className="flex justify-end space-x-4 mt-6">
      <button
        type="button"
        onClick={updateUserInDatabase}
        className="border border-[#0655A2] text-blue-600 px-4 py-2 rounded shadow hover:bg-[#0655A2] hover:text-white flex items-center justify-center"
      >
        {loading ? (
          <FaSpinner className="animate-spin mr-2" /> // Display spinner during loading
        ) : (
          "Add Wear" // Normal button text
        )}
      </button>
    </div>
  </div>
</>
);
};

export default AdminProductForm;
